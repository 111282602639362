.footer-main {
    height: 70px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    font-family: 'RalewayRegular', sans-serif;

    width: 100%;

    background-color: #fff;
}

.footer-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-left-side>span {
    font-weight: 600;
    margin-right: 2rem;
}

.footer-left-side>a {
    text-decoration: none;
    color: inherit;
}

@media screen and (max-width: 1000px) {
    .footer-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
    }

    .footer-main{
        font-size: 0.8em;
    }

    .footer-left-side{
        display: flex;
        flex-direction: column;
    }
}