.header-main {
    width: 100%;
    height: 140px;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;

    background-color: #fff;
}

.sub-header {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.sub-header-content {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.header {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
}

.header-menu-phone {
    text-decoration: none;
    color: inherit;
    font-weight: 700;
}

.header-menu-mail {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #D21531;
    font-weight: 600;
}

.header-menu-lang {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
}

@media screen and (max-width: 1000px) {
    .sub-header {
        display: none;
    }

    .header-menu {
        display: none;
    }

    .header {
        height: 100%;
    }

    .header-main {
        height: 100px;
    }
}