.ContentLink-main {
    display: flex;
    align-items: center;
    gap: 2rem;

    margin-bottom: 2rem;

}

.ContentLink-img {
    height: 100%;
}

.ContentLink-content {
    font-weight: 600;
    font-size: 1.5rem;
}

.ContentLink-link {
    text-decoration: none;
    color: #D21531;
    font-weight: 600;
    font-size: 1.2rem;
}

@media screen and (max-width: 1400px) {
    .ContentLink-main{
        margin-top: 10%;
    }
}