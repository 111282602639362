.side-menu-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow-y: scroll;
}

.header-menu-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 16%;
    padding-right: 12%;
}

.header-menu-side-contacts{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}