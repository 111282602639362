.main-content {
    height: calc(100vh - 210px);
    overflow-y: auto;
    overflow-x: auto;

    margin-top: 140px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-content::-webkit-scrollbar {
    width: 8px;
}

.main-content::-webkit-scrollbar-track {
    background-color: transparent;
}

.main-content::-webkit-scrollbar-thumb {
    background-color: rgba(136, 136, 136, 0.5);
    border-radius: 8px;
}

.main-content-layout {
    height: 100%;

    margin: 5%;
}

.content-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-block {
    width: 46%;
    height: 90%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.page-title {
    font-size: 2.5rem;
    font-weight: 600;

    width: 80%;
}

.page-text {
    font-size: 1.5rem;
    font-weight: 500;

    width: 80%;
}

@media screen and (max-width: 1400px) {
    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3%;
        width: 100%;
    }

    .content-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
        justify-content: center;
    }

    .main-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}