.container {
    width: 60%;
    margin: 0 20%;
    padding: 0;
}

@media screen and (max-width: 1600px) {
    .container {
        width: 80%;
        margin: 0 10%;
        padding: 0;
    }
}

@media screen and (max-width: 1400px) {
    .container {
        width: 90%;
        margin: 0 5%;
        padding: 0;
    }
}