.SubHeaderLink-content {
    display: flex;
    align-items: center;
    font-family: "RalewayRegular", sans-serif;
    font-weight: 600;

    color: inherit;
    font-size: 14px;
    text-decoration: none;
}

.SubHeaderLink-img {
    width: 30px;
    height: 30px;
    text-align: left;
    margin-right: auto;
}