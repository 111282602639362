.slider {
    padding: 5%;
}

.slide-images-block {
    display: flex;
    justify-content: center;
}

.slide-image {
    width: 100%;
}

.slide-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.slide-text {
    margin-bottom: 30px;
}

.slide-link {
    color: #D21531;
    text-decoration: none;
    font-weight: 600;

}


@media screen and (max-width: 1400px) {
    .slide-images-block-layout {
        top: -1%;
        left: -2%;
    }
}

@media screen and (max-width: 1000px) {
    .slide-images-block-layout {
        top: -0.5%;
        left: -2%;
    }
}